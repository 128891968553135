<template>
    <div class="login">
        <div class="login-container">
            <div class="login-type row">
                <div class="col-6">
                    <input
                        id="aroma"
                        type="radio"
                        value="1"
                        v-model="loginType"
                    />
                    <label for="aroma" class="type">
                        <div class="wrap">
                            <img src="@/assets/tgd/login/aroma.png" alt="" />
                            <div class="title">芳療師</div>
                        </div>
                    </label>
                </div>
                <div class="col-6">
                    <input
                        id="frent"
                        type="radio"
                        value="2"
                        v-model="loginType"
                    />
                    <label for="frent" class="type">
                        <div class="wrap">
                            <img
                                src="@/assets/tgd/login/frent_desk.png"
                                alt=""
                            />
                            <div class="title">櫃台</div>
                        </div>
                    </label>
                </div>
            </div>
            <div class="input-area">
                <div class="title">登入資訊</div>
                <form @submit="handleLogin">
                    <div class="position-relative" v-if="loginType === '2'">
                        <BaseSelect
                            title="分店"
                            name="store_id"
                            :options="storeList"
                        /> 
                    </div>
                    <div class="mt-3">
                        <BaseInput
                            title="帳號"
                            type="text"
                            class="form-control"
                            rootClass="input-height"
                            name="account"
                        />
                    </div>
                    <div class="position-relative mt-3 pb-4">
                        <BaseInput
                            title="密碼"
                            type="password"
                            class="form-control"
                            rootClass="input-height"
                            name="password"
                        />
                    </div>
                    <div class="input-group">
                        <button class="btn" :disabled="isSubmitting">
                            登入系統
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { postLogin } from "@/js/services/baseService";
import useSwalCustom from "@/hooks/common/useSwalCustom";

export default {
    name: "Login",
    components: {
        ErrorMessage,
    },
    setup() {
        const { state, getters, dispatch } = useStore();
        dispatch("store/getStoreList");
        const storeList = computed(()=> getters["store/selectList"]);
        const storeId = computed(() => state.auth.store_id);
        const memberId = computed(() => state.auth.member_id);
        // 登入的使用者身分別(1:芳療師, 2:櫃台)
        const loginType = ref(null);
        const customSwal = new useSwalCustom({
            title: "登入失敗!",
            icon: "error",
            confirmButtonText: "確定",
        });

        // form驗證設定
        const validationSchema = yup.object({
            account: yup.string().required("請輸入帳號"),
            password: yup.string().required("請輸入密碼"),
            store_id: yup.number().when((values, schema) => {
                return loginType.value === "2" ? schema.typeError('請選擇分店').required("請選擇分店") : schema.typeError('');
            })
        });
        const { values, handleSubmit, isSubmitting, setValues } = useForm({
            validateOnMount: false,
            validationSchema,
        });
        const router = useRouter();

        const handleLogin = handleSubmit(async (values, actions) => {
            const { account, password, store_id } = values;
            const reqData = {
                member_type: loginType.value,
                store_id,
                account,
                password,
            };
            try {
                const res = await postLogin(reqData);
                const { status, data } = res;
                if ((status === 200, data.res_code === 1)) {
                    const {
                        lounge_id,
                        store_id,
                        name,
                        token,
                        type,
                        store_name,
                        member_id,
                        hierarchy,
                        rank_name,
                    } = data.res_data;
                    dispatch("auth/login", {
                        lounge_id,
                        store_id,
                        member_name: name,
                        token,
                        member_type: type,
                        is_logged_in: true,
                        store_name,
                        member_id,
                        hierarchy,
                        rank_name,
                    });

                    customSwal
                        .fire({
                            title: "登入成功!",
                            icon: "success",
                        })
                        .then(() => {
                            router.push("/");
                            // 取得芳療師基本資訊
                            dispatch("aromaInfoForm/getMemberInfo", {
                                store_id: storeId.value,
                                member_id: memberId.value,
                            });
                        });
                } else {
                    customSwal.fire({
                        text: data.res_content,
                    });
                }
            } catch (err) {
                customSwal.fire();
                console.error(err);
            }
        });


        return {
            values,
            isSubmitting,
            handleLogin,
            loginType,
            storeList
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.login {
    overflow-y: auto;
    @include phone {
        background: no-repeat center/cover
                url("~@/assets/tgd/lightbox/side-menu-bg.png"),
            #fff;
    }
    .login-container {
        max-width: 615px;
        margin: 0 auto;
        padding: 0 15px;
        height: calc(100vh - 80px);
        @include phone {
            height: 100vh;
        }
    }
    .login-type {
        margin-bottom: 70px;
        padding-top: 20%;
        @include phone {
            margin-bottom: 50px;
            padding-top: 40%;
        }
        .col-6 {
            display: flex;
            justify-content: center;
            @include phone {
                padding: 0;
            }
            &:nth-child(1) {
                .type {
                    background: no-repeat
                            url("~@/assets/tgd/login/aroma_text.svg"),
                        transparent
                            linear-gradient(
                                180deg,
                                #c9c9c9 0%,
                                #d2d2d2 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box !important;
                    background-position: 50% 84% !important;
                    @include phone {
                        background: transparent
                            linear-gradient(
                                180deg,
                                #c9c9c9 0%,
                                #d2d2d2 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box !important;
                    }
                }
                input[type="radio"]:checked + .type {
                    background: no-repeat
                            url("~@/assets/tgd/login/aroma_text_white.svg"),
                        transparent
                            linear-gradient(180deg, #a4641a 0%, #ebc496 100%) 0%
                            0% no-repeat padding-box !important;
                    background-position: 50% 84% !important;
                    @include phone {
                        background: transparent
                            linear-gradient(180deg, #a4641a 0%, #ebc496 100%) 0%
                            0% no-repeat padding-box !important;
                    }
                }
            }
            &:nth-child(2) {
                .type {
                    background: no-repeat
                            url("~@/assets/tgd/login/frent_desk_text.svg"),
                        transparent
                            linear-gradient(
                                180deg,
                                #c9c9c9 0%,
                                #d2d2d2 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box !important;
                    background-position: 50% 83% !important;
                    @include phone {
                        background: transparent
                            linear-gradient(
                                180deg,
                                #c9c9c9 0%,
                                #d2d2d2 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box !important;
                    }
                }
                input[type="radio"]:checked + .type {
                    background: no-repeat
                            url("~@/assets/tgd/login/frent_desk_text_white.svg"),
                        transparent
                            linear-gradient(180deg, #a4641a 0%, #ebc496 100%) 0%
                            0% no-repeat padding-box !important;
                    background-position: 50% 83% !important;
                    @include phone {
                        background: transparent
                            linear-gradient(180deg, #a4641a 0%, #ebc496 100%) 0%
                            0% no-repeat padding-box !important;
                    }
                }
            }
            input[type="radio"] {
                display: none;
            }
            input[type="radio"]:checked + .type {
                box-shadow: inset 0px 3px 58px #00000029, 0px 3px 6px #00000029;
                .title {
                    color: #fff;
                }
                &:hover {
                    filter: contrast(100%);
                }
            }
            .type {
                display: flex;
                flex-direction: column;
                align-items: center;
                box-shadow: 0px 3px 6px #00000029;
                border: 3px solid rgba(255, 255, 255, 0.5);
                border-radius: 99px;
                cursor: pointer;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 255px;
                height: 327px;
                margin: 0;
                @include phone {
                    width: 165px;
                    height: 212px;
                    border-radius: 62px;
                }

                &:hover {
                    filter: contrast(130%);
                }
                .wrap {
                    position: relative;
                    top: 26px;
                    img {
                        width: 167px;
                        @include phone {
                            width: 109px;
                        }
                    }
                    .title {
                        color: #a4641a;
                        font-size: 22px;
                        text-align: center;
                        font-weight: bold;
                        margin-top: 20px;
                        @include phone {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
    .input-area {
        padding-bottom: 10px;
        form {
            span {
                font-size: 12px;
                color: #f82e2e;
                position: relative;
                top: -30px;
            }
            :deep(.base-select) {
                box-shadow: 0px 3px 6px #00000029;
                .subject-item {
                    padding: 20px;
                    line-height: 22px;
                }
                .result-item {
                    padding: 20px 30px 20px 10px;
                    line-height: 22px;
                }
            }
        }
        .title {
            font-weight: bold;
            color: #a4641a;
            margin-bottom: 15px;
            @include phone {
                color: #fff;
            }
        }

        .eyes {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 22px;
            top: 33%;
            transform: translate(-50%, -50%);
            z-index: 10;
            @include phone {
                top: 32%;
            }
        }
        .btn {
            height: 63px;
            &:active {
                background: transparent
                    linear-gradient(
                        282deg,
                        #a4641a 0%,
                        #b57831 22%,
                        #a4641a 64%,
                        #ebc496 100%
                    );
            }
        }
    }
}
</style>
